import { render, staticRenderFns } from "./activityAdd.vue?vue&type=template&id=924d5ec6&scoped=true&"
import script from "./activityAdd.vue?vue&type=script&lang=js&"
export * from "./activityAdd.vue?vue&type=script&lang=js&"
import style0 from "./activityAdd.vue?vue&type=style&index=0&id=924d5ec6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "924d5ec6",
  null
  
)

export default component.exports