<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-08-10 09:44:01
-->
<template>
	<div class="activityAdd">
		<div class="activityAdd-content">
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动名称</span>
					<el-input maxlength="15" class="common-row-ipt" placeholder="请输入"
						v-model="activityForm.activityName" />
				</div>
			</div>
			<div class="common-row">
				<div class="common-row-item">
					<span class="common-row-label">活动分类</span>
					<div class="common-row-tablist">
						<span style="margin-bottom: 0;" @click="tabSelect(item)" v-for="(item, index) in classlList"
							:key="index" :class="{'tabitem_active': activityForm.activityType == item.type}"
							class="common-row-tabitem">{{item.value}}</span>
					</div>
				</div>
			</div>
			<div class="activityAdd-timeAreas">
				<div class="activityAdd-timeAreas-content">
					<div class="common-row">
						<div class="common-row-item">
							<span class="common-row-label">开始时间</span>
							<el-date-picker class="common-row-date" value-format="yyyy-MM-dd"
								v-model="activityForm.starTime" type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
						<span style="font-size: 14px;font-family: PingFang SC;font-weight: 400;margin: 0 10px;">~</span>
						<div class="common-row-item">
							<span class="common-row-label">结束时间</span>
							<el-date-picker class="common-row-date" value-format="yyyy-MM-dd"
								v-model="activityForm.endTime" type="date" placeholder="选择日期">
							</el-date-picker>
						</div>
					</div>
					<div class="common-row">
						<div class="common-row-item">
							<span class="common-row-label">活动地址</span>
							<div style="display: flex;align-items: center;width: 100%;">
								<el-input class="common-row-ipt" placeholder="请输入活动地址"
									v-model="activityForm.address" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="activityAdd-editor">
				<div class="activityAdd-editor-title">
					活动详情
				</div>
				<quillEditor @editorChange="editorChange" :intEditor.sync="activityForm.activityDetails"
					class="handle_form_editor" />
			</div>
			<div class="flex-item" style="margin-top: 80px;">
				<el-button v-preventReClick @click="submitVerify" type="primary">提交审核</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import quillEditor from "@/components/quillEditor.vue";
	import {
		addActivity
	} from "@/api/activity";
	import storage from 'store';
	export default {
		components: {
			quillEditor
		},
		data() {
			return {
				activityForm: {
					activityType: "1",
					organizer: "",
					activityName: "",
					activityDetails: "",
					address: "",
					starTime: "",
					endTime: "",
				},
				classlList: [
					{
						type: "1",
						value: "A类"
					},
					{
						type: "3",
						value: "B类"
					},
				],
			};
		},
		mounted() {
			let usrInfo = storage.get('ACCESS_Info')
			this.activityForm.organizer = usrInfo.orgShortName
		},
		methods: {
			editorChange(props) {
				console.log("editorChange", props)
				this.activityForm.activityDetails = props
			},
			tabSelect(item) {
				this.activityForm.activityType = item.type
			},
			submitVerify() {
				console.log("submitVerify", this.activityForm)
				let verifyData = JSON.parse(JSON.stringify(this.activityForm))
				if (!verifyData.activityName) {
					this.$message.error("请填写活动名称~")
					return
				}
				if(!verifyData.starTime || !verifyData.endTime) {
					this.$message.error("请选择活动时间~")
					return
				} else {
					if (new Date((verifyData.starTime).replace(/-/g,'/')).getTime() > new Date((verifyData.endTime).replace(/-/g,'/')).getTime()) {
						this.$message.error("活动开始时间必须小于活动结束时间~")
						return
					}
				}
				if (!verifyData.address) {
					this.$message.error("请填写活动地址~")
					return
				}
				if (!verifyData.activityDetails) {
					this.$message.error("请填写活动详情~")
					return
				}
				addActivity(verifyData).then((res) => {
					if (res.code == 0) {
						this.activityForm = {
							activityType: "1",
							organizer: "",
							activityName: "",
							activityDetails: "",
							address: "",
							starTime: "",
							endTime: "",
						}
						this.$message.success("一般活动已提交审核~")
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	.flex-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.activityAdd {
		.activityAdd-content {
			padding: 20px 20px;

			.common-row {
				margin-bottom: 20px;
				
				.common-row-tablist {
					flex: 1;
					margin-left: 10px;
					display: flex;
					align-items: center;
					flex-wrap: wrap;
				
					.common-row-tabitem {
						min-width: 120px;
						height: 36px;
						line-height: 36px;
						background: #FFFFFF;
						border: 1px solid #EDF1F7;
						border-radius: 2px;
						text-align: center;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #303133;
						cursor: pointer;
						box-sizing: border-box;
						margin: 0 10px 8px 0;
				
						&.tabitem_active {
							background: rgba(28, 171, 185, 0.1);
							border: 1px solid $theme_color;
							color: $theme_color;
						}
					}
				}

				.common-row-ipt {
					flex: 1;
				}

				.common-row-date {
					width: 100%;
				}

				.row-date-icon {
					width: 18px;
					height: 18px;
					margin-left: 10px;
					cursor: pointer;
				}

				.common-row-delete {
					width: 124px;
					height: 36px;
					border: 1px solid #EDF1F7;
					border-radius: 0px 2px 2px 0px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					margin-left: 10px;

					img {
						width: 18px;
						height: 18px;
						margin-bottom: 4px;
						margin-right: 8px;
					}

					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #303133;
					}
				}
			}

			.activityAdd-timeAreas {
				display: flex;
				align-items: stretch;

				.activityAdd-timeAreas-content {
					flex: 1;
					display: flex;
					flex-direction: column;
				}
			}

			.activityAdd-editor {
				display: flex;
				flex-direction: column;

				.activityAdd-editor-title {
					background: #F6F7F9;
					border: 1px solid #EDF1F7;
					border-radius: 2px 2px 0px 0px;
					line-height: 36px;
					padding: 0 22px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #606266;
				}
			}

		}
	}
</style>
